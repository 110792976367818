import React, { useState } from 'react'
import axios from 'axios'

const Documents = ({ request }) => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const [downloadError, setDownloadError] = useState(null)

  const downloadFile = async (fileName) => {
    try {
      const response = await axios.get(
        `http://localhost:3000/download${fileName}`,
        { responseType: 'blob' }
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', fileName || 'original_document.ext')
      document.body.appendChild(link)
      link.click()
      setDownloadError(null)
    } catch (error) {
      console.error('Download Error:', error)
      setDownloadError('An error occurred while downloading the file.')
    }
  }

  return (
    <>
      {w < 960 ? (
        <div className='status-documents'>
          {request.translation ? (
            <>
              <h5>Documents</h5>
              <div className='duo'>
                {request.translation && (
                  <button
                    className='btn'
                    filename={request.translation}
                    onClick={() => downloadFile(request.translation)}
                  >
                    TRANSLATED
                  </button>
                )}
                {request.document && (
                  <button
                    className='btn'
                    onClick={() => downloadFile(request.document)}
                  >
                    ORIGINAL
                  </button>
                )}
              </div>
            </>
          ) : request.document ? (
            <>
              <h5>Document</h5>
              <button
                className='btn mt-0'
                onClick={() => downloadFile(request.document)}
              >
                {downloadError ? 'RETRY' : 'ORIGINAL'}
              </button>
            </>
          ) : (
            <div className='disabled-wrapper'>
              <h5 className='text-center'>No Document</h5>
            </div>
          )}
        </div>
      ) : (
        <div className='status-documents'>
          {request.translation ? (
            <>
              <h5 className='status-documents-title'>Documents</h5>
              <div className='duo'>
                {request.translation && (
                  <button
                    className='pri-btn'
                    filename={request.translation}
                    onClick={() => downloadFile(request.translation)}
                  >
                    <h5>TRANSLATED</h5>
                  </button>
                )}
                {request.document && (
                  <button
                    className='pri-btn'
                    onClick={() => downloadFile(request.document)}
                  >
                    <h5>{downloadError ? 'RETRY' : 'ORIGINAL'}</h5>
                  </button>
                )}
              </div>
            </>
          ) : request.document ? (
            <>
              <button
                className='pri-btn'
                onClick={() => downloadFile(request.document)}
              >
                <h5>{downloadError ? 'RETRY' : 'ORIGINAL'}</h5>
              </button>
            </>
          ) : (
            <div className='disabled-wrapper'>
              <h5 className='text-center'>No Document</h5>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Documents
