import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import lisa from '../assets/lisa.jpg'
import legal from '../assets/legal.jpg'
import medical from '../assets/medical.jpg'
import technical from '../assets/technical.jpg'
import News from '../components/News'
import news from '../news'
import home_img from '../assets/home_img.png'
import icon_fillForm from '../assets/icon_fillForm.png'
import icon_reviewForm from '../assets/icon_reviewForm.png'
import icon_priceForm from '../assets/icon_priceForm.png'
import icon_translateForm from '../assets/icon_translateForm.png'
import icon_completeForm from '../assets/icon_completeForm.png'
import marriage1 from '../assets/marriage1.png'
import icon_legal from '../assets/icon_legal.png'
import icon_technical from '../assets/icon_technical.png'
import icon_medical from '../assets/icon_medical.png'

const HomeScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { userInfo } = useSelector((state) => state.auth)

  return (
    <>
      <Header />
      <Title image={home_img} />
      {/* Mobile */}
      {w < 960 ? (
        <section className='home'>
          <div id='title'>
            <span className='top-line'>TRANSLATIONS & INTERPRETATIONS</span>
            <br />
            <span className='bottom-line'>
              Convient services tailored to your busy lifestyle!
            </span>
          </div>
          <div className='container px-4'>
            {userInfo ? (
              <h3 className='pb-3'>WELCOME {userInfo.firstName}!</h3>
            ) : (
              <h3 className='pb-3'>WELCOME</h3>
            )}
            <div className='contact-info'>
              <h1>CONTACT US</h1>
              <div className='contact-info-list'>
                <div className='contact-info-list-data'>
                  <span className='contact-info-list-data-title'>PHONE</span>
                  <span className='contact-info-list-data-info'>
                    050-6872-6649
                  </span>
                </div>
                <br />
                <div className='contact-info-list-data'>
                  <span className='contact-info-list-data-title'>EMAIL</span>
                  <span className='contact-info-list-data-info'>
                    support@centraltranslation.com
                  </span>
                </div>
              </div>
            </div>
            <h1>BEYOND LANGUAGE AND CULTURAL BARRIERS</h1>
            <p className='pb-2'>
              Central Translation Services strives to become a bridge between
              companies and individuals beyond language and cultural barriers.
              Our high-quality translations and interpretations assist you
              achieve your goals conveniently and effectively.
            </p>
          </div>
          <div className='container blue-bg'>
            <h1>TRANSLATED DOCUMENTS IN 5 SIMPLE STEPS!</h1>
            <ul>
              <li>
                <span>1</span>Complete Request Form
              </li>
              <li>
                <span>2</span>Documents Reviewed
              </li>
              <li>
                <span>3</span>Price Determination
              </li>
              <li>
                <span>4</span>Documents Translated
              </li>
              <li>
                <span>5</span>Document Pick-Up
              </li>
            </ul>
          </div>
          <div className='container'>
            <h1>NO EXPEDITE-FEE!</h1>
            <p className='px-4'>
              Our services are tailored to the busy and unpredictable lifestyles
              of our military customers. We charge no extra fee to arrange the
              same day turnaround for popular items such as affidavit, marriage
              certificate, driver’s license, etc.
            </p>
          </div>
          <div className='container blue-bg'>
            <h1>WALK-INS WELCOMED</h1>
          </div>
          <div className='container pt-3'>
            <h1>CERTIFIED & EXPERIENCED PROFESSIONALS</h1>
            <div className='py-2 px-4'>
              <img src={lisa} alt='Lisa' />
            </div>
            <p className='px-4'>
              In addition to our teamwork which guarantees high-quality
              translations and interpretations, our translators also possess
              excellent attention to detail on an individual level. They are
              certified with the Okinawa Prefecture Licensed Guide Interpreters
              and the Naha District Court Registered Interpreters.
            </p>
          </div>
          <div className='container'>
            <h1>REGISTERED</h1>
            <p className='px-4'>
              We are registered with the US Consulate General Naha and Kadena
              Air Base’s “Recommended Translators” list.
            </p>
          </div>
          <h3>LOST IN TRANSLATION?</h3>
          <div className='container'>
            <h1>SERVICES</h1>
            <div className='px-4 services-img'>
              <div className='service'>
                <Link to='services/legal' className='overlay'>
                  <h1>LEGAL</h1>
                </Link>
                <img src={legal} alt='Legal' />
              </div>
              <div className='service'>
                <Link to='services/medical' className='overlay'>
                  <h1>MEDICAL</h1>
                </Link>
                <img src={medical} alt='Medical' />
              </div>
              <div className='service'>
                <Link to='services/technical' className='overlay'>
                  <h1>TECHNICAL</h1>
                </Link>
                <img src={technical} alt='Technical' />
              </div>
            </div>
            <Link to='/services' className='mx-4 btn-secondary'>
              MORE SERVICES
            </Link>
          </div>
          <div className='pt-4 container'>
            <h1>NEWS</h1>
            <div className='px-4 services-img'>
              <Row>
                {news.map((news) => (
                  <Col key={news._id} sm={12} md={6} lg={4} xl={3}>
                    <News news={news} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
      ) : (
        // Desktop
        <section className='home-desktop'>
          <div className='home-desktop-title'>
            <div className='home-desktop-title-left'>
              <span className='upper-text'>TRANSLATIONS &</span>
              <br />
              <span className='upper-text'>INTERPRETATIONS</span>
              <br />
              <span className='lower-text'>
                Convient services tailored to your busy lifestyle!
              </span>
            </div>
            <div className='home-desktop-title-right'>
              <Link className='btn' to='/submitrequest'>
                REQUEST FORM
              </Link>
            </div>
          </div>

          <div className='home-desktop-welcome'>
            {userInfo ? (
              <h1>WELCOME {userInfo.firstName.toUpperCase()}!</h1>
            ) : (
              <h1>WELCOME!</h1>
            )}
            <div className='contact-info'>
              <h1>CONTACT US</h1>
              <div className='contact-info-list'>
                <div className='contact-info-list-data'>
                  <span className='contact-info-list-data-title'>PHONE</span>
                  <span className='contact-info-list-data-info'>
                    050-6872-6649
                  </span>
                </div>
                <br />
                <div className='contact-info-list-data'>
                  <span className='contact-info-list-data-title'>EMAIL</span>
                  <span className='contact-info-list-data-info'>
                    support@centraltranslation.com
                  </span>
                </div>
              </div>
            </div>
            <h2>BEYOND LANGUAGE AND CULTURAL BARRIERS</h2>
            <p>
              Central Translation Services strives to become a bridge between
              companies and individuals beyond language and cultural barriers.
              Our high-quality translations and interpretations assist you
              achieve your goals conveniently and effectively.
            </p>
          </div>

          <div className='home-desktop-steps'>
            <div className='bg-wrapper'>
              <h3>TRANSLATED DOCUMENTS IN 5 SIMPLE STEPS!</h3>
              <div className='steps'>
                <div className='step step1'>
                  <span>1</span>
                  <img src={icon_fillForm} alt='Step 1' />
                  <h5 className='step-title'>Complete Form</h5>
                </div>
                <div className='step step2'>
                  <span>2</span>
                  <img src={icon_reviewForm} alt='Step 1' />
                  <h5 className='step-title'>Document Review</h5>
                </div>
                <div className='step step3'>
                  <span>3</span>
                  <img src={icon_priceForm} alt='Step 1' />
                  <h5 className='step-title'>Price Determination</h5>
                </div>
                <div className='step step4'>
                  <span>4</span>
                  <img src={icon_translateForm} alt='Step 1' />
                  <h5 className='step-title'>Translate Document</h5>
                </div>
                <div className='step step5'>
                  <span>5</span>
                  <img src={icon_completeForm} alt='Step 1' />
                  <h5 className='step-title'>Document Pickup</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='home-desktop-expedite'>
            <img src={marriage1} alt='marriage1' />
            <div className='right'>
              <div className='right-text'>
                <h2>NO EXPEDITE FEE!</h2>
                <p>
                  Our services are tailored to the busy and unpredictable
                  lifestyles of our military customers. We charge no extra fee
                  to arrange the same day turnaround for popular items such as
                  affidavit, marriage certificate, driver’s license, etc.
                </p>
              </div>
              <div className='walk-ins'>
                <h2>WALK-INS WELCOMED</h2>
              </div>
            </div>
          </div>
          <div className='home-desktop-certified'>
            <div className='left'>
              <div className='left-text'>
                <h2>CERTIFIED & EXPERIENCED PROFESSIONALS</h2>
                <p>
                  In addition to our teamwork which guarantees high-quality
                  translations and interpretations, our translators also possess
                  excellent attention to detail on an individual level. They are
                  certified with the Okinawa Prefecture Licensed Guide
                  Interpreters and the Naha District Court Registered
                  Interpreters.
                </p>
              </div>
            </div>
            <img src={lisa} alt='Lisa' />
          </div>
          <div className='home-desktop-registered'>
            <div className='left'>
              <h2>REGISTERED</h2>
            </div>
            <div className='right'>
              <p>
                We are registered with the US Consulate General Naha and Kadena
                Air Base’s “Recommended Translators” list.
              </p>
            </div>
          </div>
          <h1 className='sub-title'>LOST IN TRANSLATION?</h1>
          <div className='home-desktop-services'>
            <div className='services-title'>
              <h1>SERVICES</h1>
            </div>
            <div className='block'>
              <img src={legal} alt='legal' />
              <div className='right'>
                <div className='right-text'>
                  <h2>LEGAL</h2>
                  <p>
                    We offer comprehensive legal translation services, including
                    translations of housing lease agreements, employment
                    contracts, family law documents, legal contracts, court
                    orders, and more. Our services also extend to court
                    interpretations at various locations, tax and finance
                    translations, and official document translations for
                    marriage, birth certificates, resident records, and other
                    official records and certificates.
                  </p>
                </div>
                <img src={icon_legal} alt='Legal Icon' />
              </div>
            </div>
            <div className='block'>
              <div className='left'>
                <div className='left-text'>
                  <h2>MEDICAL</h2>
                  <p>
                    Our medical translation services offer on-site medical
                    interpretations with a designated translator to accompany
                    you during your off-base doctor visits, providing you with
                    interpretation summary reports for unofficial
                    record-keeping. Additionally, we provide translations of
                    medical documents such as diagnosis reports, referral
                    letters, treatment records, treatment cost statements, as
                    well as pet document translations including vaccination
                    certificates and other related reports and certificates for
                    traveling with pets.
                  </p>
                </div>
                <img src={icon_medical} alt='Medical Icon' />
              </div>
              <img src={medical} alt='medical' />
            </div>
            <div className='block'>
              <img src={technical} alt='technical' />
              <div className='right'>
                <div className='right-text'>
                  <h2>TECHNICAL</h2>
                  <p>
                    Our technical translation services specialize in
                    translations of vehicle titles, export certificates, repair
                    quotations, yacht manuals, purchase agreement, and other
                    registration and certificate documents. Additionally, our
                    bilingual administrative services provide comprehensive
                    support for purchasing, payment plans, in-person
                    appointments, and more.
                  </p>
                </div>
                <img src={icon_technical} alt='Technical Icon' />
              </div>
            </div>
            <Link className='btn' to='/services'>
              MORE SERVICES
            </Link>
          </div>
          <div className='home-desktop-news'>
            <h2>NEWS</h2>
            <Row>
              {news.map((news) => (
                <Col key={news._id} sm={12} md={6} lg={4} xl={3}>
                  <News news={news} />
                </Col>
              ))}
            </Row>
          </div>
        </section>
      )}
      <Footer />
    </>
  )
}

export default HomeScreen
