const news = [
  {
    _id: '1',
    name: 'Okinawa International',
    image: '/images/oiu.jpg',
    url: 'https://www.okiu.ac.jp/news/48854'
  },
  {
    _id: '2',
    name: 'Tomodachi Initiative',
    image: '/images/tomodachi.jpg',
    url: 'https://www.okiu.ac.jp/news/48854'
  },
  {
    _id: '3',
    name: 'Ryukyu Shimpo',
    image: '/images/shimpo.jpg',
    url: 'https://ryukyushimpo.jp/news/entry-1295631.html'
  }
]

export default news
