// export const BASE_URL =
//   process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : ''
export const BASE_URL = ''
export const SERVICES_URL = '/api/services'
export const USERS_URL = '/api/users'
export const REQUESTS_URL = '/api/requests'
export const IMAGES_URL = '/api/image'
export const DOCUMENTS_URL = '/api/document'
export const TRANSLATIONS_URL = '/api/translation'
export const ICONS_URL = '/api/icon'
