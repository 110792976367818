import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import HeaderDark from '../components/HeaderDark'
import { Form } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import {
  useCreateRequestMutation,
  useUploadRequestDocumentMutation
} from '../slices/requestsApiSlice'
import Footer from '../components/Footer'
import { useGetServicesQuery } from '../slices/servicesApiSlice'

const RequestScreen = () => {
  const [requestItem, setRequestItem] = useState('')
  const [details, setDetails] = useState('')
  const [document, setDocument] = useState('')
  let w = window.innerWidth

  const navigate = useNavigate()

  const [createRequest, { isLoading: loadingRequest }] =
    useCreateRequestMutation()
  const { data: services } = useGetServicesQuery()

  const [uploadRequestDocument] = useUploadRequestDocumentMutation()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await createRequest({
        requestItem,
        details,
        document
      }).unwrap()
      toast.success('Request created; a representative will contact you soon.')
      navigate(`/request/${res._id}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('document', e.target.files[0])
    try {
      const res = await uploadRequestDocument(formData).unwrap()
      toast.success(res.message)
      setDocument(res.document)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  return (
    <>
      <HeaderDark />
      {w < 960 ? (
        <div className='mobile'>
          <FormContainer>
            <h1 className='text-center mt-5'>REQUEST FORM</h1>
            {loadingRequest && <Loader />}
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='requestItemService' className='my-3'>
                <Form.Select
                  type='text'
                  value={requestItem}
                  onChange={(e) => setRequestItem(e.target.value)}
                  required
                >
                  <option value=''>Service</option>
                  {services?.map((service) => (
                    <option key={service._id} value={service.name}>
                      {service.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='message' className='my-3'>
                <Form.Control
                  as='textarea'
                  rows={3}
                  placeholder='MESSAGE'
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='document' className='my-2'>
                <Form.Label>Document</Form.Label>
                <Form.Control
                  type='file'
                  label='Choose Document'
                  onChange={uploadFileHandler}
                  className='custom-field'
                ></Form.Control>
              </Form.Group>
              <button
                type='submit'
                className='button mb-5'
                disabled={loadingRequest}
              >
                SUBMIT
              </button>
            </Form>
          </FormContainer>
        </div>
      ) : (
        <div className='desktop submit-request'>
          <FormContainer>
            <h1 className='text-center mt-5'>REQUEST FORM</h1>
            {loadingRequest && <Loader />}
            <Form onSubmit={submitHandler}>
              <div className='form-flex'>
                <div className='form-flex-left'>
                  <Form.Group controlId='requestItemService'>
                    <Form.Select
                      type='text'
                      value={requestItem}
                      onChange={(e) => setRequestItem(e.target.value)}
                      required
                    >
                      <option value=''>Service</option>
                      {services?.map((service) => (
                        <option key={service._id} value={service.name}>
                          {service.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId='document'>
                    <Form.Control
                      type='file'
                      label='Choose Document'
                      onChange={uploadFileHandler}
                      name='stuff'
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className='form-flex-right'>
                  <Form.Group controlId='message'>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      placeholder='MESSAGE'
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
              <button
                type='submit'
                className='button mb-5'
                disabled={loadingRequest}
              >
                SUBMIT
              </button>
            </Form>
          </FormContainer>
        </div>
      )}

      <Footer />
    </>
  )
}

export default RequestScreen
